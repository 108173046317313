@import './mixins';

@include font-face("pn", "../../src/assets/fonts/pn100-Thin", 100);
@include font-face("pn", "../../src/assets/fonts/pn300-Light", 300);
@include font-face("pn", "../../src/assets/fonts/pn400-Regular", 400);
@include font-face("pn", "../../src/assets/fonts/pn500-Medium", 500);
@include font-face("pn", "../../src/assets/fonts/pn600-Semibold", 600);
@include font-face("pn", "../../src/assets/fonts/pn700-Bold", 700);
@include font-face("pn", "../../src/assets/fonts/pn800-Extrabld", 800);
@include font-face("pn", "../../src/assets/fonts/pn900-Black", 900);
