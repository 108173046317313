@font-face {
  font-family: "pn";
  font-weight: 100;
  src: url("../../src/assets/fonts/pn100-Thin.eot?") format("eot"), url("../../src/assets/fonts/pn100-Thin.woff2") format("woff2"), url("../../src/assets/fonts/pn100-Thin.woff") format("woff"), url("../../src/assets/fonts/pn100-Thin.ttf") format("truetype"), url("../../src/assets/fonts/pn100-Thin.otf") format("opentype");
}
@font-face {
  font-family: "pn";
  font-weight: 300;
  src: url("../../src/assets/fonts/pn300-Light.eot?") format("eot"), url("../../src/assets/fonts/pn300-Light.woff2") format("woff2"), url("../../src/assets/fonts/pn300-Light.woff") format("woff"), url("../../src/assets/fonts/pn300-Light.ttf") format("truetype"), url("../../src/assets/fonts/pn300-Light.otf") format("opentype");
}
@font-face {
  font-family: "pn";
  font-weight: 400;
  src: url("../../src/assets/fonts/pn400-Regular.eot?") format("eot"), url("../../src/assets/fonts/pn400-Regular.woff2") format("woff2"), url("../../src/assets/fonts/pn400-Regular.woff") format("woff"), url("../../src/assets/fonts/pn400-Regular.ttf") format("truetype"), url("../../src/assets/fonts/pn400-Regular.otf") format("opentype");
}
@font-face {
  font-family: "pn";
  font-weight: 500;
  src: url("../../src/assets/fonts/pn500-Medium.eot?") format("eot"), url("../../src/assets/fonts/pn500-Medium.woff2") format("woff2"), url("../../src/assets/fonts/pn500-Medium.woff") format("woff"), url("../../src/assets/fonts/pn500-Medium.ttf") format("truetype"), url("../../src/assets/fonts/pn500-Medium.otf") format("opentype");
}
@font-face {
  font-family: "pn";
  font-weight: 600;
  src: url("../../src/assets/fonts/pn600-Semibold.eot?") format("eot"), url("../../src/assets/fonts/pn600-Semibold.woff2") format("woff2"), url("../../src/assets/fonts/pn600-Semibold.woff") format("woff"), url("../../src/assets/fonts/pn600-Semibold.ttf") format("truetype"), url("../../src/assets/fonts/pn600-Semibold.otf") format("opentype");
}
@font-face {
  font-family: "pn";
  font-weight: 700;
  src: url("../../src/assets/fonts/pn700-Bold.eot?") format("eot"), url("../../src/assets/fonts/pn700-Bold.woff2") format("woff2"), url("../../src/assets/fonts/pn700-Bold.woff") format("woff"), url("../../src/assets/fonts/pn700-Bold.ttf") format("truetype"), url("../../src/assets/fonts/pn700-Bold.otf") format("opentype");
}
@font-face {
  font-family: "pn";
  font-weight: 800;
  src: url("../../src/assets/fonts/pn800-Extrabld.eot?") format("eot"), url("../../src/assets/fonts/pn800-Extrabld.woff2") format("woff2"), url("../../src/assets/fonts/pn800-Extrabld.woff") format("woff"), url("../../src/assets/fonts/pn800-Extrabld.ttf") format("truetype"), url("../../src/assets/fonts/pn800-Extrabld.otf") format("opentype");
}
@font-face {
  font-family: "pn";
  font-weight: 900;
  src: url("../../src/assets/fonts/pn900-Black.eot?") format("eot"), url("../../src/assets/fonts/pn900-Black.woff2") format("woff2"), url("../../src/assets/fonts/pn900-Black.woff") format("woff"), url("../../src/assets/fonts/pn900-Black.ttf") format("truetype"), url("../../src/assets/fonts/pn900-Black.otf") format("opentype");
}
.ng-select.ng-select-opened > .ng-select-container {
  background: #fff;
  border-color: #b3b3b3 #ccc #d9d9d9;
}

.ng-select.ng-select-opened > .ng-select-container:hover {
  box-shadow: none;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  top: -2px;
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow:hover {
  border-color: transparent transparent #333;
}

.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.ng-select.ng-select-opened.ng-select-top > .ng-select-container {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #f9f9f9;
}

.ng-select .ng-has-value .ng-placeholder {
  display: none;
}

.ng-select .ng-select-container {
  color: #333;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
  min-height: 36px;
  align-items: center;
}

.ng-select .ng-select-container:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}

.ng-select .ng-select-container .ng-value-container {
  align-items: center;
  padding-left: 10px;
}

[dir=rtl] .ng-select .ng-select-container .ng-value-container {
  padding-right: 10px;
  padding-left: 0;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #999;
}

.ng-select.ng-select-single .ng-select-container {
  height: 36px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 5px;
  left: 0;
  padding-left: 10px;
  padding-right: 50px;
}

[dir=rtl] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  padding-right: 10px;
  padding-left: 50px;
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
  background-color: #f9f9f9;
  border: 1px solid #e6e6e6;
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value .ng-value-label {
  padding: 0 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-top: 5px;
  padding-left: 7px;
}

[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
  padding-right: 7px;
  padding-left: 0;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  font-size: 0.9em;
  margin-bottom: 5px;
  background-color: #ebf5ff;
  border-radius: 2px;
  margin-right: 5px;
}

[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  margin-right: 0;
  margin-left: 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
  background-color: #f9f9f9;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
  padding-left: 5px;
}

[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
  padding-left: 0;
  padding-right: 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  display: inline-block;
  padding: 1px 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  display: inline-block;
  padding: 1px 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
  background-color: #d1e8ff;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid #b8dbff;
}

[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-left: 1px solid #b8dbff;
  border-right: none;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-left: 1px solid #b8dbff;
}

[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-left: 0;
  border-right: 1px solid #b8dbff;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0 0 3px 3px;
}

[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
  padding: 0 3px 3px 0;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 5px;
  padding-bottom: 5px;
  padding-left: 3px;
}

[dir=rtl] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  padding-right: 3px;
  padding-left: 0;
}

.ng-select .ng-clear-wrapper {
  color: #999;
}

.ng-select .ng-clear-wrapper:hover .ng-clear {
  color: #D0021B;
}

.ng-select .ng-spinner-zone {
  padding: 5px 5px 0 0;
}

[dir=rtl] .ng-select .ng-spinner-zone {
  padding: 5px 0 0 5px;
}

.ng-select .ng-arrow-wrapper {
  width: 25px;
  padding-right: 5px;
}

[dir=rtl] .ng-select .ng-arrow-wrapper {
  padding-left: 5px;
  padding-right: 0;
}

.ng-select .ng-arrow-wrapper:hover .ng-arrow {
  border-top-color: #666;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}

.ng-dropdown-panel {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  left: 0;
}

.ng-dropdown-panel.ng-select-bottom {
  top: 100%;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-color: #e6e6e6;
  margin-top: -1px;
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.ng-dropdown-panel.ng-select-top {
  bottom: 100%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-color: #e6e6e6;
  margin-bottom: -1px;
}

.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.ng-dropdown-panel .ng-dropdown-header {
  border-bottom: 1px solid #ccc;
  padding: 5px 7px;
}

.ng-dropdown-panel .ng-dropdown-footer {
  border-top: 1px solid #ccc;
  padding: 5px 7px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  user-select: none;
  padding: 8px 10px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.54);
  cursor: pointer;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
  cursor: default;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
  background-color: #f5faff;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
  background-color: #ebf5ff;
  font-weight: 600;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  padding: 8px 10px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: #333;
  background-color: #ebf5ff;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
  font-weight: 600;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #f5faff;
  color: #333;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: #ccc;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-left: 22px;
}

[dir=rtl] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-right: 22px;
  padding-left: 0;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  font-size: 80%;
  font-weight: 400;
  padding-right: 5px;
}

[dir=rtl] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
  padding-left: 5px;
  padding-right: 0;
}

[dir=rtl] .ng-dropdown-panel {
  direction: rtl;
  text-align: right;
}

video-background {
  overflow: hidden;
}

.video-background, video-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.video-background {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  background-size: cover;
  transition: opacity 1s;
}

.video-controls {
  position: absolute;
  width: 50%;
  display: block;
  height: 50px;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  left: 0;
  text-align: center;
  padding-bottom: 20px;
  transition: 15ms;
}

.video-controls span {
  font-size: 2.8em;
  color: #f5f5f5;
  font-family: sans-serif;
  font-weight: 600;
}

html {
  height: 100%;
}

body {
  font-family: pn;
  color: #FFF;
  background: #080e24;
  height: 100%;
  background-image: url(../assets/img/bg-03.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bg-b-40 {
  background: rgba(0, 0, 0, 0.4);
}

.bg-b-60 {
  background: rgba(0, 0, 0, 0.6);
}

.container-main {
  background-size: contain;
  background-position: center;
  height: 100%;
}

h1 {
  font-size: 30px;
  font-weight: 800;
}

.home {
  max-width: 300px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}
.home h1 {
  margin: 0;
  padding-bottom: 25px;
}
.home .home-btn {
  display: block;
  margin-bottom: 15px;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  padding: 16px 14px;
  font-size: 17px;
  font-weight: 700;
  color: #fff;
  position: relative;
  line-height: normal;
  min-height: 80px;
  text-align: center;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.home .home-btn:hover, .home .home-btn:active, .home .home-btn:focus {
  background: rgba(0, 0, 0, 0.7);
}
.home .home-btn span {
  display: block;
  padding-top: 10px;
}
.home .tim-logo {
  padding: 75px 50px;
}

.bg-video {
  position: fixed;
  z-index: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.bg-video:after {
  content: "";
  position: absolute;
  z-index: 2;
  background: #001432;
  opacity: 0.7;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.bg-video video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 1;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.search {
  position: relative;
}
.search .search-box {
  height: 60px;
  overflow: hidden;
  position: relative;
  background: #00373f;
}
.search .search-box span {
  display: block;
  position: absolute;
  z-index: 4;
  line-height: 60px;
  padding-left: 60px;
  left: 0;
  right: 0;
  top: 0;
  cursor: pointer;
  opacity: 1;
  filter: alpha(opacity=${opacity-ie});
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.search .search-box img {
  position: absolute;
  z-index: 3;
  left: 0;
  top: 0;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 1;
  filter: alpha(opacity=${opacity-ie});
}
.search .search-box input {
  position: relative;
  width: 100%;
  padding: 0 20px;
  line-height: 60px;
  background: #fff;
  border: none;
  color: #000;
  z-index: 2;
  opacity: 0;
  filter: alpha(opacity=${opacity-ie});
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.search .search-box input:focus {
  outline: none;
  border: none;
}
.search .search-box a {
  display: block;
  color: #22c2dc;
  line-height: 60px;
  height: 60px;
  padding: 0 15px;
  position: absolute;
  z-index: 4;
  right: 0;
  top: 0;
  background: rgba(255, 255, 255, 0);
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.search .search-box.focus img {
  opacity: 0;
  filter: alpha(opacity=${opacity-ie});
  z-index: 0;
}
.search .search-box.focus span {
  opacity: 0;
  filter: alpha(opacity=${opacity-ie});
  z-index: 0;
}
.search .search-box.focus input {
  opacity: 1;
  filter: alpha(opacity=${opacity-ie});
}
.search .search-box.focus a {
  background: white;
}
.search .search-result {
  overflow: auto;
}
.search .search-result .s-items-count {
  padding: 8px 20px;
  background: rgba(0, 0, 0, 0.6);
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
}
.search .search-result .s-item {
  display: block;
  margin-top: 1px;
  padding: 10px 40px 10px 20px;
  position: relative;
  background: rgba(0, 0, 0, 0.3);
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  cursor: pointer;
}
.search .search-result .s-item:after {
  content: "";
  width: 26px;
  height: 26px;
  position: absolute;
  background: url(../assets/img/arrow-right.svg) no-repeat;
  right: 10px;
  top: 50%;
  margin-top: -13px;
  z-index: 1;
}
.search .search-result .s-item:hover, .search .search-result .s-item:active, .search .search-result .s-item:focus {
  background: rgba(0, 0, 0, 0.4);
}
.search .search-result .s-item.s-item-hscode {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  text-transform: uppercase;
  line-height: normal;
}
.search .search-result .s-item.s-item-hscode .hs6-code {
  font-size: 14px;
  opacity: 0.5;
  filter: alpha(opacity=${opacity-ie});
  font-weight: 400;
}
.search .search-result .s-item.s-item-hscode .hs6-name {
  font-weight: 400;
  font-size: 14px;
  opacity: 0.5;
  filter: alpha(opacity=${opacity-ie});
  padding-top: 4px;
}
.search .search-result .description {
  text-align: center;
  padding: 40px;
}

.country-selector {
  max-width: 250px;
  margin: 0 auto;
  padding-top: 200px;
  text-align: center;
}
.country-selector h1 {
  font-size: 30px;
  font-weight: 800;
}

.ng-select .ng-select-container {
  border-radius: 0;
  border-color: #fff;
}
.ng-select.ng-select-single .ng-select-container {
  height: 40px;
}
.ng-select.ng-select-opened > .ng-select-container {
  border-radius: 0;
  border-color: #fff;
}

.ng-dropdown-panel.ng-select-bottom {
  border-color: #fff;
  border-radius: 0;
}

.country-imports {
  max-width: 780px;
  margin: 0 auto;
  position: relative;
}
.country-imports header {
  padding: 20px 0;
  line-height: normal;
  font-size: 14px;
  font-weight: 300;
}
.country-imports header .back-arrow {
  width: 45px;
}
.country-imports header h1 {
  line-height: 36px;
  font-size: 30px;
  font-weight: 900;
  margin: 0;
  text-transform: uppercase;
}
.country-imports header .hscode {
  margin-bottom: 15px;
}
.country-imports header .hscode strong {
  font-size: 18px;
  font-weight: 800;
}
.country-imports header .year {
  font-weight: 700;
}
.country-imports header app-currency-formatter {
  font-weight: 700;
}
.country-imports header app-currency-formatter .suffix::before {
  content: " ";
}
.country-imports .no-result {
  width: 100%;
  max-width: 400px;
  padding: 45px;
}

.contacts header .hscode strong {
  font-weight: 600;
}
.contacts .cards {
  padding: 0 20px;
}
.contacts .contact {
  margin-bottom: 30px;
  font-size: 19px;
  color: rgba(255, 255, 255, 0.8);
  line-height: normal;
}
.contacts .contact .card-title {
  padding: 15px 17px;
  background: #22c2dc;
  margin-bottom: 1px;
}
.contacts .contact .card-title h2 {
  font-size: 21px;
  font-weight: 700;
  margin: 0;
  color: #fff;
}
.contacts .contact .card-title .job-title {
  color: #fff;
  font-size: 17px;
}
.contacts .contact .card-title .company {
  font-size: 17px;
}
.contacts .contact .contact-info {
  padding: 10px 0;
  font-weight: 300;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
  padding: 10px 17px;
  padding-right: 10px;
  margin-bottom: 1px;
  font-size: 16px;
  min-height: 68px;
}
.contacts .contact .contact-info:nth-child(even) {
  background: rgba(0, 0, 0, 0.2);
}
.contacts .contact .contact-info:nth-child(even) a {
  background: rgba(0, 0, 0, 0.4);
}
.contacts .contact .contact-info .label {
  font-weight: normal;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  padding-top: 7px;
}
.contacts .contact .contact-info a {
  width: 48px;
  height: 48px;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  margin-left: 15px;
  text-align: center;
  border-radius: 50%;
}
.contacts .contact .contact-info a:hover, .contacts .contact .contact-info a:active, .contacts .contact .contact-info a:focus {
  background: #007bff;
}
.contacts .contact a {
  color: rgba(255, 255, 255, 0.8);
}

.imports {
  padding: 0 45px;
}
.imports .table {
  margin: 0;
}
.imports .table th,
.imports .table td {
  color: #fff;
  padding: 8px;
  line-height: normal;
  font-size: 14px;
  font-weight: 300;
  border: none;
}
.imports .imports-list-header .table {
  background: #22C2DC;
}
.imports .imports-list-header .table th {
  font-weight: 700;
  font-size: 12px;
}
.imports .imports-list-header .table th.imports {
  width: 170px;
  text-align: right;
  padding-right: 38px;
}
.imports .imports-list-body .table {
  margin-bottom: 1px;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.imports .imports-list-body .table tbody tr {
  cursor: pointer;
}
.imports .imports-list-body .table tbody td {
  border: none;
}
.imports .imports-list-body .table tbody td.gtip {
  width: 40px;
  font-weight: 700;
  font-size: 16px;
  text-align: right;
}
.imports .imports-list-body .table tbody td.desc b {
  display: block;
  font-size: 14px;
  font-weight: 700;
}
.imports .imports-list-body .table tbody td.desc.country {
  vertical-align: middle;
  font-size: 16px;
  font-weight: 700;
  padding-left: 20px;
}
.imports .imports-list-body .table tbody td.imports {
  width: 130px;
  text-align: right;
}
.imports .imports-list-body .table tbody td.imports .value {
  font-weight: 800;
  font-size: 28px !important;
  display: block;
  line-height: normal;
}
.imports .imports-list-body .table tbody td.imports .suffix {
  font-weight: 300;
  font-size: 10px;
  display: block;
  margin-top: -2px;
}
.imports .imports-list-body .table tbody td.arrow {
  width: 30px;
  text-align: center;
  vertical-align: middle;
  padding-left: 0;
  padding-right: 4px;
}
.imports .imports-list-body .table:nth-child(odd) {
  background: rgba(0, 0, 0, 0.3);
}
.imports .imports-list-body .table:nth-child(even) {
  background: rgba(0, 0, 0, 0.2);
}
.imports .imports-list-body .table:hover, .imports .imports-list-body .table:active, .imports .imports-list-body .table:focus {
  background: rgba(0, 0, 0, 0.6);
}

.btn {
  border-radius: 0;
  border: none;
  height: 50px;
  padding: 0 30px;
}
.btn.btn-lg {
  font-size: 16px;
}
.btn.btn-primary {
  background-color: #0F5FA6;
}
.btn.btn-primary:hover, .btn.btn-primary:active, .btn.btn-primary:focus {
  background-color: #007bff;
  box-shadow: none;
}

a {
  color: #66A8B2;
}
a:hover, a:active, a:focus {
  text-decoration: none;
  color: #aacfd4;
}

.footer {
  font-size: 14px;
  padding: 30px;
  text-align: center;
}
.footer a {
  color: rgba(255, 255, 255, 0.4);
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.footer a:hover, .footer a:active, .footer a:focus {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
}
.footer .f-link-2 {
  color: rgba(255, 255, 255, 0.2);
}

.country-import-details .details-number-holder {
  padding: 25px 10px 0 10px;
  padding-left: 45px;
}
.country-import-details .details-number {
  padding-bottom: 25px;
}
.country-import-details .details-number h4 {
  font-size: 12px;
  line-height: normal;
  color: rgba(255, 255, 255, 0.6);
  max-width: 120px;
  font-weight: 500;
}
.country-import-details .details-number .value {
  display: block;
  font-size: 46px;
  font-weight: 800;
  line-height: 46px;
}
.country-import-details .details-number .suffix {
  display: block;
  line-height: normal;
  font-size: 12px;
  font-weight: 300;
}

.chart-area {
  padding: 25px 15px;
}
.chart-area .chart-header {
  text-align: center;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 400;
  padding-bottom: 20px;
}
.chart-area .chart-header h3 {
  margin: 0;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
}

.table-top-importers {
  color: #fff;
  font-size: 13px;
  font-weight: 400;
}
.table-top-importers td,
.table-top-importers th {
  padding: 8px 10px;
}
.table-top-importers thead th {
  border: none;
  background: #22C2DC;
  padding: 6px 10px;
}
.table-top-importers tbody td {
  border: none;
  border-bottom: solid 1px #006e7f;
}
.table-top-importers tbody td .suffix:before {
  content: " ";
}
.table-top-importers tbody tr:nth-child(even) {
  background: rgba(0, 0, 0, 0.3);
}
.table-top-importers tbody tr:nth-child(odd) {
  background: rgba(0, 0, 0, 0.2);
}
.table-top-importers tbody tr.turkey {
  background: #f00;
}

.form-control {
  border-radius: 0;
  border-color: #fff;
}
.form-control:hover, .form-control:active, .form-control:focus {
  border-color: #fff;
}

.hs-selector-modal.nsm-dialog {
  max-width: 780px;
}
.hs-selector-modal.nsm-dialog .nsm-content {
  margin: 0 45px;
  padding: 0;
  box-shadow: none;
  background: transparent;
}
.hs-selector-modal.nsm-dialog .nsm-content .nsm-body {
  width: 100%;
  margin-top: 75px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.hs-selector-modal.nsm-dialog .nsm-content .nsm-body .title {
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  color: #b1b1b1;
  padding-bottom: 25px;
}
.hs-selector-modal.nsm-dialog .nsm-content .nsm-body .title h1 {
  font-size: 30px;
  font-weight: 800;
  margin: 0;
  color: #fff;
  margin-bottom: 10px;
}
.hs-selector-modal.nsm-dialog .nsm-content .nsm-dialog-btn-close img {
  display: none;
}
.hs-selector-modal.nsm-dialog .nsm-content .nsm-dialog-btn-close:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background: url(../assets/img/e-remove.svg) no-repeat center;
}

.hs-search-result {
  margin-top: 1px;
  overflow-y: auto;
  min-height: 300px;
}
.hs-search-result .hs-code-item {
  background: #fff;
  color: #000;
  margin-bottom: 1px;
  padding: 13px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  line-height: normal;
}
.hs-search-result .hs-code-item .hs6-code {
  font-size: 14px;
  font-weight: 400;
  color: #aaaaaa;
}
.hs-search-result .hs-code-item .hs6-name {
  font-size: 12px;
  font-weight: 400;
  padding-top: 5px;
  color: #a2a2a2;
}
.hs-search-result .hs-code-item:hover, .hs-search-result .hs-code-item:active, .hs-search-result .hs-code-item:focus {
  background: #eee;
}

@media (max-width: 575px) {
  .container-main {
    background-size: auto;
    background-position-x: center;
    background-position-y: 170px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 991px) {
  .country-import-details .details-number .value {
    display: block;
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
  }
}
@media (max-width: 767px) {
  .country-selector {
    padding-top: 150px;
  }

  .country-imports header .back-arrow {
    width: 35px;
  }

  .imports {
    padding: 0 35px;
  }
  .imports .imports-list-body .table td {
    font-size: 13px;
    line-height: normal;
  }
  .imports .imports-list-body .table tbody td.imports {
    width: 100px;
    padding-right: 0;
  }
  .imports .imports-list-body .table tbody td.imports .value {
    font-size: 24px !important;
  }
  .imports .imports-list-body .table tbody td.gtip {
    padding-right: 0;
    width: 30px;
  }

  .footer {
    padding-left: 0;
    padding-right: 0;
  }

  .hs-selector-modal.nsm-dialog .nsm-content {
    margin: 0;
  }
}
@media (min-width: 992px) {
  .bg-b-40-md {
    background: rgba(0, 0, 0, 0.4);
  }

  .bg-b-60-md {
    background: rgba(0, 0, 0, 0.6);
  }
}