$color-bg: #006E7F;
$color-bg: #080e24;
$color-text: #FFF;
$color-table-header: #22C2DC;
$color-primary: #0F5FA6;
$color-bar: #A3A1FB;
$color-link: #66A8B2;
$color-link-hover: lighten($color-link, 20%) ;

$color-overlay: rgba(0, 0, 0, .8);
$dialog-position-top: 5%;

$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;


